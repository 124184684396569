<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren('/stats') }"
    v-if="checkHasPermissions(['buFuntap', 'sourceMoney', 'daily', 'pointDaily'])"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-analytics"></i>
      <span class="menu-text">Thống kê</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Thống kê</span>
          </span>
        </li>

        <router-link
          to="/stats/bu-funtap"
          v-slot="{ href, navigate, isActive, isExactActive }"
          v-if="hasPermission('buFuntap')"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">BU Funtap</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/stats/source-money"
          v-slot="{ href, navigate, isActive, isExactActive }"
          v-if="hasPermission('sourceMoney')"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Nguồn tiền</span>
            </a>
          </li>
        </router-link>

        <router-link
          to="/balance/daily"
          v-slot="{ href, navigate, isActive, isExactActive }"
          v-if="hasPermission('daily')"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Số dư theo ngày</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/point/daily"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="hasPermission('pointDaily')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Số point theo ngày</span>
            </a>
          </li>
        </router-link>

          <router-link
              to="/report/funcard"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('funcard')"
          >
              <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
              >
                  <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                      </i>
                      <span class="menu-text">Funcard</span>
                  </a>
              </li>
          </router-link>
      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";
import checkPermissions from "../../../core/mixins/permissionsMiddleware";

export default {
  name: "SidebarStats",
  mixins: [Common],
  methods: {
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
    checkHasPermissions(permissions) {
      return checkPermissions(permissions);
    }
  },
};
</script>

<style scoped></style>
